@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  background: #000;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: #000;
  width: 100%;
  position: relative;
  padding-top: 72px;

  @media (max-width: 800px) {
    padding-top: 32px;
  }
}

.text-center {
  text-align: center;
}

.section-bg {
  background: #000;
  color: #fff;
  padding: 32px 0;

  a {
    color: #e10b0b;
  }

  &.light {
    background: #ddd;
    color: #000;

    a {
      color: #b40404;
    }

    h1, h2, h3, h4, h5, h6 {
      color: #000;
    }
  }

  &.hero {
    background-size: cover !important;
    background-repeat: no-repeat !important;;

    @media (min-width: 1920px) {
      background: url('./hero-bg-big.jpg');
    }

    @media (max-width: 1919px) {
      background: url('./hero-bg-medium.jpg');
    }

    @media (max-width: 800px) {
      background: url('./hero-bg-small.jpg');
    }
  }
}

.center-container {
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 16px;

  &.no-max-width {
    max-width: none;
  }
}

h1, h2 {
  color: #fff;
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  .dark-bg {
    background: rgba(0, 0, 0, 0.8);
    color: #c90000;
    padding: 8px 32px;
  }
}

.header-presskit {
  @media (max-width: 339px) {
    display: none;
  }
}

h1 {
  font-size: 6rem;
  margin-top: 0 !important;

  .small {
    font-size: .65em;
    font-weight: normal;
    margin-bottom: 0 !important;
  }

  .big {
    display: block;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
    line-height: .65em;
  }

  &.hero-h1 {
    margin-top: -30px !important;

    @media (max-width: 1280px) {
      margin-top: -30px !important;
    }

    @media (max-width: 720px) {
      margin-top: 20px !important;
    }
  }

  @media (max-width: 1280px) {
    font-size: 4rem;
  }

  @media (max-width: 800px) {
    font-size: 3rem;
  }

  @media (max-width: 299px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2rem;

  @media (max-width: 1280px) {
    font-size: 1rem;
  }
}

.mb-none {
  margin-bottom: 0;
}

.mt-none {
  margin-top: 0;
}

.video-container {
  margin: 16px auto;
  max-width: 50%;

  @media (max-width: 1280px) {
    max-width: 100%;
  }

  &.full-width {
    max-width: 100%;
  }
}

.np-logo-hero {
  max-width: 50%;
  margin: 0 auto;
  display: block;

  @media (max-width: 720px) {
    display: none;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.steam-cta {
  width: 600px;
  max-width: 100%;
  height: 200px;
  margin: 16px auto;
  display: block;
  padding: 0;

  @media (max-width: 1280px) {
    width: 100%;
  }
}

.header {
  z-index: 9999;
  background: rgba(0, 0, 0, 1);
  position: fixed;
  width: 100vw;
  top: 0;
  height: 72px;

  @media (max-width: 800px) {
    .social-icons-container {
      display: none !important;
    }

    text-align: center;
  }

  .center-container {
    padding: 0;
  }

  .social-icons-container {
    margin-top: 20px !important;

    .social-icon {
      margin: 0 8px !important;
    }
  }

  .header-logo-link {
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-weight: bold;

    &:hover {
      color: #fff;
    }

    &:focus {
      color: #fff;
    }

    .header-logo {
      margin-top: 4px;
      max-width: 200px;
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
    }

    span {
      font-size: 1.2rem;
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  .header-nav {
    margin-left: 16px;
    display: inline-block;

    @media (max-width: 1319px) {
      display: none;
    }

    a {
      font-size: 1rem;
      text-decoration: none;
      color: #aaa;
      vertical-align: middle;
      margin-left: 12px;

      &:hover {
        color: #fff;
        text-decoration: none;
      }

      &:focus {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.social-banner-link {
  width: 50%;
  max-width: 616px;
  margin: 8px auto;
  display: block;
  position: relative;

  img {
    width: 100%;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }
}

.faq {
  font-size: 1.4rem;

  @media (max-width: 1280px) {
    font-size: 1.2rem;
    padding-top: 16px;
  }

  h2 {
    font-size: 3rem;
    margin-top: 0;

    @media (max-width: 1280px) {
      font-size: 2rem;
    }
  }

  .faq-entry {
    h3 {
      font-size: 2rem;

      @media (max-width: 1280px) {
        font-size: 1.5rem;
      }
    }

    ul {
      list-style-type: disc;
    }
  }
}

.social-icons-container {
  margin: 8px 0;

  &.small {
    margin: 0;

    .social-icon {
      margin: 0 2px;
      width: 32px !important;
      height: 32px !important;
    }
  }

  .social-icon {
    margin: 0 4px;
  }
}

.inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.mad-raven-games-logo {
  width: 200px;
}

table {
  tbody {
    vertical-align: top;
    text-align: left;

    th, td {
      padding: 8px;
    }
  }
}

.dl-image {
  img {
    max-width: 48%;
    margin: 1%;

    @media (max-width: 720px) {
      max-width: 100%;
    }
  }
}